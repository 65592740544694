<template>
  <select
    :class="selectClass"
    v-model="selectedOption"
    @change="notifyValueChange"
  >
    <option v-for="option in options" :key="option" :value="option">
      {{ option }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "medium",
      validator: function(val) {
        return ["small", "medium", "large"].indexOf(val) !== -1;
      }
    },
    options: {
      type: Array,
      default: function() {
        return [10, 25, 50, 100];
      }
    },
    defaultOption: {
      type: Number,
      default: 10
    }
  },

  data() {
    return {
      selectedOption: this.defaultOption
    };
  },

  computed: {
    selectClass() {
      let base = "browser-default";
      let select = "";
      switch (this.size) {
        case "small":
          select = "custom-select-sm";
          break;
        case "medium":
          select = "custom-select";
          break;
        case "large":
          select = "custom-select-lg";
          break;
      }

      return base + " " + select;
    }
  },

  methods: {
    notifyValueChange() {
      this.$emit("value-changed", this.selectedOption);
    }
  }
};
</script>
