<template>
  <a class="d-inline-block">
    <slot></slot>
    <span v-if="isSorted">
      <!-- Font Awesome Vue components are needed to ensure proper reactivity to
      property changes.-->
      <font-awesome-icon v-if="isAscending" :icon="['fas', 'sort-up']"/>
      <font-awesome-icon v-else :icon="['fas', 'sort-down']"/>
    </span>
    <span v-else>
      <font-awesome-icon :icon="['fas', 'sort']"/>
    </span>
  </a>
</template>

<script>
export default {
  props: {
    isSorted: {
      type: Boolean,
      required: true
    },

    isAscending: {
      type: Boolean,
      required: true
    }
  }
};
</script>
